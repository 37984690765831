/*!
 * Bootstrap v5.2.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// doejo
$charity-blue: #6895B5;
$charity-blue-dark: #5784A4;
$charity-blue-light: #75AED6;
$hero-blue: #337EB6;
$charity-yellow: #F7B500;

// Create your own map
$custom-colors: (
    "charity-yellow": $charity-yellow,
    "charity-blue": $charity-blue,
    "charity-blue-dark": $charity-blue-dark,
    "charity-blue-light": $charity-blue-light,
    "hero-blue": $hero-blue
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// Validation
$form-validation-states: map-remove($form-validation-states, "valid");

@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// String replace
// @function str-replace($string, $search, $replace: '') {
//     $index: str-index($string, $search);

//     @if $index {
//         @return str-slice($string, 1, $index - 1)+$replace +str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
//     }

//     @return $string;
// }

html {
    scroll-padding-top: 6rem;
}

// Custom CSS
// NAVBAR
.link-charity-blue {
    color: #212529 !important;
}

.link-charity-blue:hover,
.link-charity-blue:focus {
    color: $hero-blue  !important;
}

.bi.bi-phone-fill {
    height: 1.5rem;
    width: auto;
    fill: $charity-blue;
    vertical-align: text-bottom;
}

.bi.bi-telephone-fill {
    vertical-align: -3px;
}

// GENERAL
hr.content-overline {
    color: $charity-yellow;
    border-top-width: 5px;
    width: 6rem;
    opacity: 1;
}

hr.hero-overline {
    color: white;
    border-top-width: 5px;
    width: 12rem;
    opacity: 1;
}

.svg-blue>svg {
    width: 5rem;
    height: auto;
    fill: $charity-blue;
}

.svg-white>svg {
    width: 7rem;
    height: auto;
    fill: white;
}

.svg-yellow>svg {
    width: 3rem;
    height: auto;
    fill: $charity-yellow;
}

// INCLUDES
#footerTop {
    background: linear-gradient(to right bottom, $charity-yellow, rgba($charity-yellow, .5));
}

@include media-breakpoint-up(md) {
    #footerTop> :first-child {
        padding-left: 15rem;
    }
}

#footerBottom {
    background-color: $gray-600;
}

@include media-breakpoint-up(md) {
    #footerBottom> :first-child {
        padding-left: 15rem;
    }
}

#charityStates {
    background-image: linear-gradient(rgba(black, 0.8), rgba(black, 0.8)), url("/src/images/charity-states.png?as=webp&width=1920");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#charityStates>ul {
    list-style-image: url("/src/images/icons/check2-circle.svg");
}

#questionsContact {
    background-image: linear-gradient(rgba($charity-blue, .9), rgba($charity-blue, .9)), url("/src/images/questions-contact-bg.png?as=webp&width=1920");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

// HOME
#homeHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .1), rgba($charity-blue-dark, .1)), url("/src/images/heros/goodwill-car-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom 5rem;
    padding-top: 6rem;
}

@include media-breakpoint-up(lg) {
    #homeHero {
        padding-top: 7rem;
    }
}

.bi.bi-arrow-right {
    width: 2rem;
    height: auto;
}

#howCADWorks {
    background-image: linear-gradient(rgba(black, 0.8), rgba(black, 0.8)), url("/src/images/how-cad-works.jpg?as=webp&width=1920");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}

#aboutUs {
    background-image: linear-gradient(to right, rgba($charity-blue-dark, 1), rgba($charity-blue-dark, .5));
}

// #youtubeABCVideo {
//     min-width: 30rem;
// }

// VEHICLES
#vehiclesHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .8), rgba($charity-blue-dark, .8)), url("/src/images/heros/vehicles-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40rem;
}

#whatCanYouDonateCardsRow>.col-md-6 {
    max-width: 30rem;
}

.what-can-you-donate-card-row:hover {
    background-color: $charity-yellow  !important;
}

.what-can-you-donate-card-row:hover span {
    color: $charity-blue  !important;
}

.what-can-you-donate>img {
    width: 4rem;
    height: 4rem;
}

#typicalVehiclesDonated {
    list-style-image: url("/src/images/icons/check-square-fill.svg");
}

#typicalVehiclesDonatedImg {
    background-image: url("/src/images/toy-cars.jpg?as=webp&width=800");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

// PLACES
#placesHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .8), rgba($charity-blue-dark, .8)), url("/src/images/heros/places-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40rem;
}

#decideWhenCard {
    background-image: linear-gradient(rgba(black, 0.7), rgba(black, 0.7)), url("/src/images/decide-when-card-bg.png?as=webp&width=800");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#pickupPresentCard {
    background-image: linear-gradient(rgba(black, 0.8), rgba(black, 0.8)), url("/src/images/pickup-present-card-bg.png?as=webp&width=800");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.how-it-works>svg {
    width: 5rem;
    height: auto;
    fill: white;
}

#donateYourCarPlaces {
    background-image: linear-gradient(rgba(white, .9), rgba(white, .9)), url("/src/images/heros/vehicles-hero.png?as=webp&width=1920");
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(white, 0.2);
}

// FAQs
#faqsHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .8), rgba($charity-blue-dark, .8)), url("/src/images/heros/faqs-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40rem;
}

// DONATE
#charitiesHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .8), rgba($charity-blue-dark, .8)), url("/src/images/heros/donate-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40rem;
}

#allNonProfitsList img {
    max-width: 200px;
}

// CONTACT US
#contactHero {
    background-image: linear-gradient(rgba($charity-blue-dark, .8), rgba($charity-blue-dark, .8)), url("/src/images/heros/contact-hero.png?as=webp&width=1920");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 40rem;
}

// DONATION FORM
// Allow carousel to work with flexbox form
@include media-breakpoint-up(xxl) {
    #donationCard {
        max-width: 40rem;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: flex;
}

.donation-form-icon {
    max-height: 2rem;
    max-width: 2rem;
}

@include media-breakpoint-up(xxl) {
    .donation-form-icon {
        max-height: 3rem;
        max-width: 3rem;
    }
}

.invalid-feedback {
    display: block;
    visibility: hidden;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    visibility: visible;
}

.was-validated .invalid-left.form-control:invalid,
.invalid-left.form-control.is-invalid {
    background-position: left calc(0.375em + 0.1875rem) center;
}